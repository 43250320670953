<template>
  <v-app>
    <div class="area">
      <v-row justify="center" align="center" class="text-center">
        <v-container>
            <v-avatar size="150" tile>
                <v-container>
                    <v-img :src="$config.logo" />
                </v-container>
            </v-avatar>
            <h2 class="mt-2" style="color: #122940">
                {{ $config.title }}
            </h2>
            <h2 class="mt-2" style="color: #122940">
                منظومة سير العمل الإداري
            </h2>
            <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
        </v-container>
    </v-row>
    <br />
    <br />
    
      <div class="wrapper">
        <!-- <div class="card">
          <div
            class="text-center d-flex justify-center flex-column align-center"
          >
            <v-img
              width="100"
              src="@/assets/images/graduation.png"
              class="mb-4"
            ></v-img>
            <h2>انشاء وثيقة تخرج</h2>
          </div>
        </div>
        <div class="card">
          <div
            class="text-center d-flex justify-center flex-column align-center"
          >
            <v-img
              width="100"
              src="@/assets/images/comments.png"
              class="mb-4"
            ></v-img>
            <h2>إنشاء معاملة تسليم الاطروحة او الرسالة</h2>
          </div>
        </div>
        <div class="card">
          <div
            class="text-center d-flex justify-center flex-column align-center"
          >
            <v-img
              width="100"
              src="@/assets/images/news-paper.png"
              class="mb-4"
            ></v-img>
            <h2>معاملة اعتمادية مجلة</h2>
          </div>
        </div>
        <div class="card">
          <div class="text-center">
            <h2>معاملة اعتمادية مجلة</h2>
          </div>
        </div> -->
        <div
          class="card"
          v-for="item in workflows"
          :key="item.id"
          @click="getOrderData(item.id)"
        >
          <div class="text-center">
            <h2>{{ item.name }}</h2>
          </div>
        </div>
      </div>
      <!-- </v-col> -->
      <v-footer>
        <v-row justify="center">
          <div class="d-flex justify-center">
            <span style="color: #7c7c7c">
              {{ $t("tm-alttwyr-bwasth") }}
              <a href="https://www.google.com/">
                {{ $config.company }}
              </a>
              |
              {{ new Date().getFullYear() }}
            </span>
          </div>
        </v-row>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import validations from "@/utils/validations";
export default {
  components: {},
  data() {
    return {
      user: {},
      loading: false,
      process: {},
      workflows: null,
      workflowId: null,
      overData: null,
      valid: true,
      ...validations,
    };
  },
  created() {
    this.process = process.env;
    this.getWorkFlows();
  },

  methods: {
    async submit() {
      this.loading = true;
    },
    async getWorkFlows() {
      this.loading = true;
      try {
        const res = await this.$http.get("Workflow");
        this.workflows = res.data.result;
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    getOrderData(itemID) {
      this.$router.push({
        path: `/treatment/${itemID}`,
      });
    },
    test() {
      console.log("this is clickable");
    },
  },
};
</script>
<style lang="scss">
.head {
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-radius: 0px 0px 36px 36px;
  padding: 1rem;
}
.continer {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  margin: 2rem;
}

.area {
  background: #ffffff;
  background-size: 50% auto;
  background: white;
  width: 100%;
  height: 100%;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fill, 370px);
  height: 100%;
  gap: 2rem;
  margin: 2rem;
}
.card {
  width: 100%;
  height: 360px;
  border-radius: 15px;
  padding: 1.5rem;
  background: #f5f5f5;
  color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.card:hover {
  transform: translateY(20px);
}
.card:hover:before {
  opacity: 1;
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
</style>

<!-- 
  <v-row  align="center" style="height: 100%">
        <v-col class="text-center" cols="12">
          <v-divider vertical="" inset="" class="mx-2" />
          <v-card
            outlined
            class="mx-auto text-center"
            width="400px"
            style="z-index: 2"
          >
            <v-container>
              <v-avatar size="150" tile>
                <v-container>
                  <v-img :src="$config.logo" />
                </v-container>
              </v-avatar>
              <h2 class="mt-2" style="color: #122940">
                {{ $config.title }}
              </h2>
              <h2 class="mt-2" style="color: #122940">
                منظومة سير العمل الإداري
              </h2>
              <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
              <br />
              <v-form @submit.prevent="submit" ref="form" v-model="valid">
                <v-autocomplete
                  clearable
                  v-model="workflowId"
                  item-text="name"
                  item-value="id"
                  :items="workflows"
                  class="mr-1"
                  dense=""
                  :label="$t('nwa-almaamlh')"
                  :loading="loading"
                  :no-data-text="$t('no-data')"
                  hide-details=""
                  prepend-inner-icon="date_range"
                  outlined=""
                  filled=""
                />
                <br />

                <v-btn
                  class="btn"
                  :loading="loading"
                  :disabled="workflowId ? false : true"
                  type="submit"
                  color="primary"
                  x-large
                  block
                  @click="getOrderData()"
                >
                  {{ $t("next") }}
                </v-btn>
              </v-form>
            </v-container>
          </v-card>
        </v-col>

        <v-col>
          <v-row justify="center">
            <span style="color: #7c7c7c">
              {{ $t("tm-alttwyr-bwasth") }}
              <a href="https://www.google.com/">
                {{ $config.company }}
              </a>
              |
              {{ new Date().getFullYear() }}
            </span>
          </v-row>
        </v-col>
      </v-row> -->
